<template>
	<div v-if="node && node.is_document">
		<v-flex v-if="simpleButtonDisplay && !listTileDisplay && !singleTileDisplay" shrink>
			<w-btn v-if="canEditUniqueNumber" icon="fas fa-hashtag" mini v-bind="$attrs" @click="showDialogEditUniqueNumber()">{{
				node.number ? $t('documents.document_number') + ' : ' + node.number : $t('documents.edit_number')
			}}</w-btn>
		</v-flex>
		<v-list-tile v-else-if="listTileDisplay && !singleTileDisplay" avatar @click="showDialogEditUniqueNumber()">
			<v-list-tile-avatar>
				<v-icon color="primary">fas fa-hashtag</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title v-t="'documents.edit_number'" />
			</v-list-tile-content>
		</v-list-tile>
		<v-list-tile v-else-if="singleTileDisplay">
			<v-list-tile-content>
				<v-list-tile-title>{{ $t('documents.document_number') }}</v-list-tile-title>
				<v-list-tile-sub-title>{{ node.number }}</v-list-tile-sub-title>
			</v-list-tile-content>
			<v-list-tile-action>
				<v-btn v-if="canEditUniqueNumber" icon @click="showDialogEditUniqueNumber()">
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-icon color="grey lighten-1" v-on="on">edit</v-icon>
						</template>
						<span v-t="'documents.edit_number'" />
					</v-tooltip>
				</v-btn>
			</v-list-tile-action>
		</v-list-tile>
		<UniqueNumberDialog v-if="showEditUniqueNumberDialog" v-model="node" @close="hideDialogEditUniqueNumber()" />
	</div>
</template>

<script>
import { mapState } from 'vuex'

import DocumentActionModuleGuard from '@/mixins/ModulesGuards/Documents/Actions/DocumentActionModuleGuard'

export default {
	name: 'UniqueNumberButton',
	components: {
		UniqueNumberDialog: () => ({
			component: import('@/components/Documents/Actions/UniqueNumber/UniqueNumberDialog')
		})
	},
	mixins: [DocumentActionModuleGuard],
	props: {
		singleTileDisplay: {
			type: Boolean,
			required: false,
			default: false
		},
		value: {
			type: [Object, Array],
			required: false,
			default: () => []
		}
	},
	data: function () {
		return {
			showEditUniqueNumberDialog: false
		}
	},
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm.selected
		}),
		nodes: function () {
			return Array.isArray(this.value) && this.value ? this.value : [this.value] || []
		},
		node: function () {
			return this.nodes && this.nodes.length == 1 ? this.nodes[0] : null
		},
		canEditUniqueNumber: function () {
			return (
				this.node &&
				!this.node?.is_deleted &&
				this.node?.folder_id &&
				this.accountingFirm &&
				this.accountingFirm.isAccountant &&
				!this.node?.is_readonly &&
				!this.node?.is_trash &&
				(this.node?.is_document || !this.node.is_default)
			)
		}
	},
	methods: {
		showDialogEditUniqueNumber: function () {
			if (this.canEditUniqueNumber) {
				this.showEditUniqueNumberDialog = true
			}
		},
		hideDialogEditUniqueNumber: function () {
			this.showEditUniqueNumberDialog = false
		}
	}
}
</script>
